import { userAxios } from '../../index'

const treatmentCategoryList = async (paginate) => {
    try {
        return await userAxios.get(`categoryTreatments/all/${paginate}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentCategoryCreate = async (data) => {
    try {
        return await userAxios.post('categoryTreatments', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentCategoryUpdate = async (id, data) => {
    try {
        return await userAxios.put(`categoryTreatments/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentCategoryDelete = async (id) => {
    try {
        return await userAxios.delete(`treatments/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentCategoryPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`categoryTreatments/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentCategoryFilter = async (data) => {
    try {
        return await userAxios.post('categoryTreatments/search', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const treatmentCategoryFilterPagination = async (page, data) => {
    try {
        return await userAxios.post(`categoryTreatments/search?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    treatmentCategoryList,
    treatmentCategoryCreate,
    treatmentCategoryUpdate,
    treatmentCategoryDelete,
    treatmentCategoryPagination,
    treatmentCategoryFilter,
    treatmentCategoryFilterPagination
}